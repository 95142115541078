/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
} */

.contact-section {
    padding-top: 150px;
    padding-bottom: 75px;
    background-color: #00204a;
    color: #fff;
}

.contact-container {
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.section-header {
  margin-bottom: 50px;
  text-align: center;
}

.section-header h2 {
  color: #fff;
  font-weight: bold;
  font-size: 3em;
  margin-bottom: 20px;
}

.section-header p {
  color: #fff;
}

.contact-row  {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.contact-info {
  width: 50%;
}

.contact-info-item {
  display: flex;
  margin-bottom: 30px;
}

.contact-info-icon {
  height: 70px;
  width: 70px;
  background-color: #fff;
  text-align: center;
  border-radius: 50%;
}

.contact-info-icon i {
  font-size: 30px;
  line-height: 70px;
  color: #A09857;
}

.contact-info-content {
  margin-left: 20px;
}

.contact-info-content h4 {
  color: #A09857;
  font-size: 1.4em;
  margin-bottom: 5px;
}

.contact-info-content p {
  color: #fff;
  font-size: 1em;
  overflow-wrap: anywhere;
}

.contact-form {
  background-color: #fff;
  border-radius: 8px;
  padding: 40px;
  width: 45%;
  padding-bottom: 20px;
  padding-top: 20px;
}

.contact-form h2 {
  font-weight: bold;
  font-size: 2em;
  margin-bottom: 10px;
  color: #A09857;
}

.contact-form .input-box {
  position: relative;
  width: 100%;
  margin-top: 10px;
}

.contact-form .input-box input,
.contact-form .input-box textarea{
  width: 100%;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  border: none;
  border-bottom: 2px solid #333;
  outline: none;
  resize: none;
}

.contact-form .input-box span {
  position: absolute;
  left: 0;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  pointer-events: none;
  transition: 0.5s;
  color: #666;
}

.contact-form .input-box input:focus ~ span,
.contact-form .input-box textarea:focus ~ span{
  color: #A09857;
  font-size: 12px;
  transform: translateY(-20px);
}

.contact-form .input-box input[type="submit"]
{
  width: 100%;
  background: #A09857;
  color: #FFF;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 18px;
  border: 1px solid #A09857;
  transition: 0.5s;
}

.contact-form .input-box input[type="submit"]:hover
{
  background: #FFF;
  color: #7F7545;
}

@media (max-width: 991px) {
  .contact-section {
    padding-top: 150px;
    padding-bottom: 50px;
    /* background-color: #fff; */
  }
  
  .contact-row {
    flex-direction: column;
  }
  
  .contact-info {
    margin-bottom: 40px;
    width: 100%;
  }
  
  .contact-form {
    width: 100%;
  }
}

@media (max-width: 340px) {
  .contact-info-icon {
    height: 40px;
    width: 40px;
    background-color: #fff;
    text-align: center;
    border-radius: 50%;
  }

  .contact-info-icon i {
    font-size: 20px;
    line-height: 40px;
    color: #A09857;
  }
  .contact-info-content p {
    color: #fff;
    font-size: .9em;
    overflow-wrap: anywhere;
  }
}